import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentLinks, Establishments, PaymentLinksService, EstablishmentsService } from 'api-client';
import { take } from 'rxjs/operators';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-payment-grerj',
  templateUrl: './payment-grerj.component.html',
  styleUrls: ['./payment-grerj.component.scss']
})
export class PaymentGrerjComponent implements OnInit {
  paymentLink: PaymentLinks;
  establishment: Establishments;
  linkId: string;
  finishStepMessage: string;

  constructor(
    private _paymentLinksService: PaymentLinksService,
    private _establishmentsService: EstablishmentsService,
    private _route: ActivatedRoute,
    private _logger: LogService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.linkId = this._route.snapshot.paramMap.get('id');

    this._logger.tag('linkId', this.linkId);

    this._paymentLinksService
      .apiPaymentLinksIdGet(this.linkId)
      .pipe(take(1))
      .subscribe({
        next: paymentLink => {
          this.paymentLink = paymentLink;

          this._establishmentsService
            .apiEstablishmentsIdGet(paymentLink.establishmentID)
            .pipe(take(1))
            .subscribe({
              next: establishment => {
                this.establishment = establishment;

                if (this.paymentLink.linkType === 1) {
                  this._router.navigate(['/pay-checkout/' + this.linkId]);
                }

                if (this.paymentLink.paid && (this.paymentLink.linkType === 0 || this.paymentLink.linkType === 2)) {
                  this._router.navigate(['/paid-link']);
                }
                if (this.paymentLink.expired && (this.paymentLink.linkType === 0 || this.paymentLink.linkType === 2)) {
                  this._router.navigate(['/expired-link']);
                }
                if (this.paymentLink.canceled) {
                  this._router.navigate(['/canceled-link']);
                }

                this.establishment = establishment;
              }
            });
        },
        error: error => {
          this._logger.debug('Invalid link.');
          this._router.navigate(['/invalid-link']);
        }
      });
  }

  updateMessage(message: string) {
    this.finishStepMessage = message;
  }
}
