import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paid-link',
  templateUrl: './paid-link.component.html',
  styleUrls: ['./paid-link.component.scss']
})
export class PaidLinkComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
