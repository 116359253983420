<form [formGroup]="orderForm">
  <div class="cards-bar my-4">
    <div class="form-container0">
      <div class="flex flex-col mb-4">
        <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Nome</label>
        <input
          id="name"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="name"
          type="text"
          [attr.disabled]="true" />
      </div>
      <div class="flex flex-col mb-4">
        <label for="establishmentName" class="block text-gray-700 text-sm font-bold mb-2">Estabelecimento</label>
        <input
          id="establishmentName"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="establishmentName"
          type="text"
          [attr.disabled]="true" />
      </div>
      <div class="flex flex-row gap-4 mb-4">
        <div class="flex flex-col">
          <label for="orderNumber" class="block text-gray-700 text-sm font-bold mb-2">{{
            paymentLink.linkType === 2 ? 'Número da guia' : 'Encomenda'
          }}</label>
          <input
            id="orderNumber"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="orderNumber"
            type="text"
            [attr.disabled]="true" />
        </div>
        <div class="flex flex-col">
          <label for="valueEUR" class="block text-gray-700 text-sm font-bold mb-2">Valor em {{ linkCurrency }}</label>
          <input
            id="valueEUR"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="valueEUR"
            type="text"
            [attr.disabled]="true"
            style="text-align: right" />
        </div>
      </div>
      <div class="flex flex-col mb-4">
        <label for="observations" class="block text-gray-700 text-sm font-bold mb-2">{{
          paymentLink.linkType === 2 ? 'Número do processo' : 'Observações'
        }}</label>
        <textarea
          id="observations"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="observations"
          type="text"
          [attr.disabled]="true"></textarea>
      </div>
      <div class="flex flex-col">
        <button
          type="submit"
          id="continuar"
          matStepperNext
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Continuar
        </button>
      </div>
    </div>
    <div></div>
  </div>
  <cards-bar></cards-bar>
</form>
