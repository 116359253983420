import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Establishments, PaymentLinks } from 'api-client';

@Component({
  selector: 'confirm-step',
  templateUrl: './confirm-step.component.html',
  styleUrls: ['./confirm-step.component.scss']
})
export class ConfirmStepComponent implements OnInit, OnChanges {
  @Input() paymentLink: PaymentLinks;
  @Input() establishment: Establishments;

  orderForm: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  get establishmentName() {
    return this.establishment?.establismentName;
  }

  get establishmentCurrency() {
    return this.establishment?.currencyCode;
  }

  get linkCurrency() {
    return this.paymentLink?.currencyCode;
  }

  ngOnInit(): void {
    this._initOrderForm();
  }

  private _initOrderForm() {
    this.orderForm = this._formBuilder.group({
      name: [this.paymentLink?.customerName || ''],
      valueEUR: [this.paymentLink?.valueEUR || 0],
      orderNumber: [this.paymentLink?.orderNumber || ''],
      observations: [this.paymentLink?.observations || ''],
      establishmentName: [this.establishmentName || '']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.orderForm?.patchValue({
      name: this.paymentLink?.customerName || '',
      valueEUR: this.paymentLink?.valueEUR || 0,
      orderNumber: this.paymentLink?.orderNumber || '',
      observations: this.paymentLink?.observations || '',
      establishmentName: this.establishmentName || ''
    });
  }
}
