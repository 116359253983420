import { Component, Input } from '@angular/core';

@Component({
  selector: 'cards-bar',
  templateUrl: './cards-bar.component.html',
  styleUrls: ['./cards-bar.component.scss']
})
export class CardsBarComponent {
  @Input() paymentGateway?: string = 'Rede';
}
