import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Sentry from '@sentry/browser';
import { Logs, LogsService } from 'api-client';
import { environment } from 'src/environments/environment';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

@Injectable({
  providedIn: 'root'
})
export class LogService {
  prodLogMinumumLevel: LogLevel = LogLevel.Error;
  logWithDate: boolean = true;

  constructor(private injector: Injector) {}

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
    this.sendToSentry(msg);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
    this.sendToSentry(msg);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  tag(msg: string, ...optionalParams: any[]) {
    console.log('tag ', msg);
    this.writeToLog(msg, LogLevel.Info, optionalParams);
    this.addSentryTag(msg, optionalParams[0]);
  }

  context(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
    this.addSentryContext(msg, optionalParams[0]);
  }

  toast(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
    this.openSnackBar(msg);
  }

  toastError(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
    this.openSnackBar(msg);
    this.sendToSentry(msg);
  }

  toastAndLogToSentry(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
    this.openSnackBar(msg);
    this.sendToSentry(msg);
  }

  logToSentry(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
    this.sendToSentry(msg);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;

    if (
      (environment.production && level >= this.prodLogMinumumLevel && level !== LogLevel.Off) ||
      !environment.production
    ) {
      ret = true;
    }

    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    let value: string = '';
    let severity: string = '';

    switch (level) {
      case LogLevel.Debug:
        severity = '[INFO]';
        break;
      case LogLevel.Info:
        severity = '[INFO]';
        break;
      case LogLevel.Warn:
        severity = '[WARN]';
        break;
      case LogLevel.Error:
        severity = '[ERROR]';
        break;
      case LogLevel.Fatal:
        severity = '[ERROR]';
        break;
      default:
        severity = '[INFO]';
    }

    const context = this.addContextInfo(value, severity);
    params.push(context);

    value = '[' + environment.name + '][' + environment.env.toUpperCase() + '] ';
    value += severity + ' ' + msg;

    value += ' - Extra Info: ' + this.formatParams(params);

    this.sendToLogs(value, level);

    if (this.shouldLog(level)) {
      switch (level) {
        case LogLevel.Debug:
          console.log(value);
          break;
        case LogLevel.Info:
          console.info(value);
          break;
        case LogLevel.Warn:
          console.warn(value);
          break;
        case LogLevel.Error:
          console.error(value, params);
          break;
        case LogLevel.Fatal:
          console.error(value);
          break;
        default:
          console.log(value);
      }
    }
  }

  openSnackBar(message: string) {
    const snackBar = this.injector.get(MatSnackBar);
    snackBar.open(message, 'X', {
      duration: 3000
    });
  }

  addSentryTag(tagName: string, tagValue: string) {
    Sentry.setTag(tagName, tagValue);
  }

  addSentryContext(contextName: string, context: object) {
    Sentry.setContext(contextName, context);
  }

  sendToSentry(message: string) {
    Sentry.captureMessage(message);
  }

  sendToLogs(value: string, level: number) {
    const logsService = this.injector.get(LogsService);
    let log: Logs = {
      message: value,
      level: level,
      timeStamp: new Date().toISOString(),
      fileName: 'not specified',
      lineNumber: '0'
    };

    logsService.apiLogsPost(log).subscribe(result => {
      console.log('Log registado com sucesso.');
    });
  }

  addContextInfo(message: string, severity: string) {
    const appId = environment.appId;
    const time = new Date();
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const env = environment.env;

    const contextInfo = {
      appId,
      time,
      url,
      env,
      message,
      severity
    };
    return contextInfo;
  }

  private formatParams(params: any[]): string {
    let ret: string = '';

    for (let item of params) {
      ret += JSON.stringify(item) + ',';
    }

    return ret;
  }
}
