export class NewNotification {
  emailAddress?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
  emailTitle?: string;
  imageToUse?: string;
  language?: string;
}
