import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CardIssuerFees,
  CountriesService,
  DreampayPayment,
  DreampayPaymentResponse,
  Establishments,
  PaymentLinks,
  PaymentsService,
  Settings,
  SettingsService,
  SettingsUSDService
} from 'api-client';
import { getValidationConfigFromCardNo } from 'src/app/helpers/card.helper';
import { Translations } from 'src/app/models/translations.model';
import { LogService } from 'src/app/services/log.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { GenericValidator } from 'src/app/validators/cpfValidator';
import { luhnValidator } from 'src/app/validators/luhnValidator';
import { environment } from 'src/environments/environment';
import { Transaction } from 'src/app/models/transaction.class';
import { NewNotification } from 'src/app/models/notification.class';

@Component({
  selector: 'payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss']
})
export class PaymentStepComponent implements OnInit, OnChanges {
  @Input() paymentLink: PaymentLinks;
  @Input() establishment: Establishments;

  @Output() onMessageChanged: EventEmitter<string> = new EventEmitter<string>();
  private serviceSubscription: Subscription = new Subscription();

  ccForm: FormGroup;
  bloco1;
  bloco2;
  bloco3;
  bloco4;
  bloco5;
  blocks;

  bankName = 'DreamPay';
  cardImgPath: string;
  isCredit: boolean = true;
  transaction: Transaction = new Transaction();
  linkCurrency: string;

  currentEnvironment: string = environment.env;
  timer: Subscription;
  cardIssuerFees: CardIssuerFees;
  dreampayPayment: DreampayPayment;
  paymentResponse: DreampayPaymentResponse;
  failedPayNotification = new NewNotification();
  settings: Settings;
  finishStepMessage: string;

  creditOnly: boolean = false;
  minimumInstallments: number = 1;
  defaultInstallments: number = 12;

  private _translations: Translations = new Translations();

  constructor(
    private _formBuilder: FormBuilder,
    private _paymentsService: PaymentsService,
    private _countriesService: CountriesService,
    private _settingsService: SettingsService,
    private _settingsUSDService: SettingsUSDService,
    private _sharedFunctionsService: SharedFunctionsService,
    private _logger: LogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ccForm) {
      this.ccForm.patchValue({
        name: '',
        cardNumber: '',
        cpf: '',
        expirationDate: '',
        securityCode: ''
      });
    }
  }

  ngOnInit(): void {
    this.linkCurrency = this.paymentLink?.currencyCode.toUpperCase();
    this._initCCForm();
    this._initSettings();
  }

  private _initSettings() {
    this._countriesService
      .apiCountriesIdGet(this.establishment.countryID)
      .pipe(take(1))
      .subscribe(country => {
        const currencySymbol = country.currencySymbol;

        if (!this.paymentLink.paid && !this.paymentLink.canceled && !this.paymentLink.expired) {
          this._sharedFunctionsService.sendAlertOpened(this.paymentLink, this.establishment, currencySymbol);
        }

        this._sharedFunctionsService.initTranslations(country.uiLanguage.trim()).subscribe(response => {
          this._translations = response;
        });

        this.ccForm.controls.parcelas.setValue(1);

        const ano = new Date().getFullYear();
        const mes = new Date().getUTCMonth() + 1;
        const dia = new Date().getUTCDate();
        const intDate = ano * 10000 + mes * 100 + dia;

        if (this.linkCurrency === 'EUR') {
          this._settingsService
            .apiSettingsDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                this.settings = settings;
                this.transaction.exchangeRate =
                  Math.round(
                    this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread) * 10000 + 0.5
                  ) / 10000;
                this.calculateAmountToPay();
                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else if (this.linkCurrency === 'USD') {
          this._settingsUSDService
            .apiSettingsUSDDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                this.settings = settings;
                this.transaction.exchangeRate =
                  Math.round(
                    this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread) * 10000 + 0.5
                  ) / 10000;
                this.calculateAmountToPay();
                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else if (this.linkCurrency === 'BRL') {
          this._settingsService
            .apiSettingsDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                this.settings = settings;
                this.settings.oficialExchangeRate = 1;
                this.settings.iof = 0;
                this.settings.spread = 0;

                this.transaction.exchangeRate = 1;
                this.creditOnly = true;
                this.minimumInstallments = 2;
                this.ccForm.controls.parcelas.setValue(this.defaultInstallments);

                this.calculateAmountToPay();
                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else {
          this.settings = null;
        }
      });
  }

  private _initCCForm() {
    this.ccForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(27)]),
      cardNumber: new FormControl('', [Validators.required, Validators.minLength(11), luhnValidator()]),
      cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
      expirationDate: new FormControl('', [Validators.required, Validators.maxLength(7), expDateValidators]),
      securityCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(1),
        Validators.max(9999)
      ]),
      credit: new FormControl('1', [Validators.required]),
      parcelas: new FormControl(this.minimumInstallments),
      totalToPay: new FormControl(0)
    });

    this.serviceSubscription.add(
      this.ccForm.controls.cardNumber.valueChanges.subscribe(
        val => {
          this.blocks = this.ccForm.controls.cardNumber.value.split(' ');
          if (Array.isArray(this.blocks)) {
            this.bloco1 = this.blocks[0];
            this.bloco2 = this.blocks[1];
            this.bloco3 = this.blocks[2];
            this.bloco4 = this.blocks[3];
            this.bloco5 = this.blocks[4];
          } else {
            this.bloco1 = '';
            this.bloco2 = '';
            this.bloco3 = '';
            this.bloco4 = '';
            this.bloco5 = '';
          }
          this.bankName = 'DreamPay';
        },
        error => {
          this._logger.error('Erro nos dados do cartão.', error);
        }
      )
    );
  }

  getCardNumberControl(): AbstractControl | null {
    return this.ccForm?.get('cardNumber');
  }

  onCardNumberBlur() {
    this.calculateAmountToPay();
  }

  getExpirationDateControl(): AbstractControl | null {
    return this.ccForm?.get('expirationDate');
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onOptionsSelected(value: string) {
    this.calculateAmountToPay();
  }

  onCreditSelected(value: string) {
    console.log('value', value);
    this.calculateAmountToPay();
  }

  onRadioChange($event) {
    console.log('onRadioChange', $event);
    if ($event.value === '1') {
      this.isCredit = true;
      this.ccForm.controls.parcelas.setValue(this.minimumInstallments);
    } else {
      this.isCredit = false;
    }

    this.calculateAmountToPay();
  }

  calculateAmountToPay() {
    if (this.settings) {
      let totalValueBRL: number;
      let totalValueEUR: number;
      let installmentValueBRL: number;
      let efectiveExchangeRate: number =
        this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread);
      let exchangeCostBRL: number;
      let cardProcessingCostBRL: number;
      let maxAntecipationCostBRL: number;
      let cardProcessmentFee: number;

      if (this.isCredit) {
        switch (this.ccForm.controls.parcelas.value) {
          case 1:
            cardProcessmentFee = this.settings.cardProcessmentFee;
            break;
          case 2:
            cardProcessmentFee = this.settings.twoMonthsFee;
            break;
          case 3:
            cardProcessmentFee = this.settings.threeMonthsFee;
            break;
          case 4:
            cardProcessmentFee = this.settings.fourMonthsFee;
            break;
          case 5:
            cardProcessmentFee = this.settings.fiveMonthsFee;
            break;
          case 6:
            cardProcessmentFee = this.settings.sixMonthsFee;
            break;
          case 7:
            cardProcessmentFee = this.settings.sevenMonthsFee;
            break;
          case 8:
            cardProcessmentFee = this.settings.eightMonthsFee;
            break;
          case 9:
            cardProcessmentFee = this.settings.nineMonthsFee;
            break;
          case 10:
            cardProcessmentFee = this.settings.tenMonthsFee;
            break;
          case 11:
            cardProcessmentFee = this.settings.elevenMonthsFee;
            break;
          case 12:
            cardProcessmentFee = this.settings.twelveMonthsFee;
            break;

          default:
            cardProcessmentFee = this.settings.twelveMonthsFee;
            break;
        }
      } else {
        cardProcessmentFee = this.settings.debitCardProcessmentFee;
      }

      this.transaction.exchangeRate = efectiveExchangeRate;

      if (this.isCredit) {
        if (this.paymentLink.establishmentSupportsFinancingCosts) {
          totalValueEUR = this.paymentLink.valueEUR / (1 - this.settings.operacionalMargin - cardProcessmentFee);
        } else {
          totalValueEUR =
            this.paymentLink.valueEUR /
            (1 - this.settings.operacionalMargin - this.settings.antecipationFee - cardProcessmentFee) /
            (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
        }
        totalValueBRL = totalValueEUR * this.transaction.exchangeRate;
        installmentValueBRL = totalValueBRL / this.ccForm.controls.parcelas.value;
        this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));

        exchangeCostBRL = this.paymentLink.valueEUR * this.transaction.exchangeRate;
        cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
        maxAntecipationCostBRL =
          totalValueBRL /
          (1 - this.settings.antecipationFee) /
          (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
      } else {
        this.ccForm.controls.parcelas.setValue(1);
        totalValueEUR = this.paymentLink.valueEUR / (1 - cardProcessmentFee - this.settings.operacionalMargin);
        totalValueBRL = totalValueEUR * this.transaction.exchangeRate;
        installmentValueBRL = totalValueBRL;

        this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));
        exchangeCostBRL = this.paymentLink.valueEUR * this.transaction.exchangeRate;
        cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
        maxAntecipationCostBRL = 0;
      }

      this.transaction.cardProcessingComission = cardProcessmentFee * 100;
      this.transaction.estimatedExchangeCostBRL = Math.round(exchangeCostBRL * 100 + 0.5) / 100;
      this.transaction.cardProcessingCostBRL = Math.round(cardProcessingCostBRL * 100 + 0.5) / 100;
      this.transaction.maxAntecipationCostBRL = Math.round(maxAntecipationCostBRL * 100 + 0.5) / 100;
      this.transaction.totalValueBRL = Math.round(totalValueBRL * 100 + 0.5) / 100;
      this.transaction.totalValueEUR = Math.round(totalValueEUR * 100 + 0.5) / 100;
      this.transaction.installmentValueBRL = Math.round(installmentValueBRL * 100 + 0.5) / 100;
    }
  }

  submeter() {
    console.log('submeter');
    this.finishStepMessage = 'Por favor aguarde enquanto processamos o pagamento...';
    this.onMessageChanged.emit(this.finishStepMessage);
    this._logger.context('form', this.ccForm.value);

    this._logger.context('isCredit', {
      isCredit: this.isCredit,
      valueEUR: this.paymentLink.valueEUR
    });

    this._logger.sendToSentry('Payment button clicked.');

    this.dreampayPayment = {
      posID: 'payment-link',
      establishmentID: this.establishment.id,
      softDescriptor: this.establishment.softDescriptor,
      establismentName: this.establishment.establismentName,
      cardHolder: this.ccForm.controls.name.value,
      cardNumber: this.ccForm.controls.cardNumber.value.replaceAll(' ', ''),
      cardExpirationDate:
        this.ccForm.controls.expirationDate.value.slice(0, 2) +
        '/20' +
        this.ccForm.controls.expirationDate.value.slice(2, 4),
      securityCode: this.ccForm.controls.securityCode.value,
      cardType: this.isCredit ? 'credit' : 'debit',
      orderNumber: (
        this.establishment.id.toString() +
        this._sharedFunctionsService.generateRandomLetter() +
        this.paymentLink.orderNumber
      ).substring(0, 16),
      customerName: this.paymentLink.customerName,
      purchaseValueEUR: this.paymentLink.valueEUR,
      totalValueEUR: this.transaction.totalValueEUR,
      totalValueBRL: 100 * this.transaction.installmentValueBRL * this.ccForm.controls.parcelas.value,
      installmentValueBRL: this.transaction.installmentValueBRL,
      paymentMethod: this.transaction.paymentMethod,
      installments: this.ccForm.controls.parcelas.value,
      readMethod: this.transaction.readMethod,
      cpFofCardOwner: this.ccForm.controls.cpf.value,
      cpfName: '-',
      exchangeRate: this.transaction.exchangeRate,
      paymentLink: this.paymentLink.id,
      customerEmail: this.paymentLink.customerEmail,
      currencyCode: this.establishment.currencyCode,
      customerPhoneNumber: '-',
      customerAddress: '-',
      customerZipCode: '-',
      customerCity: '-',
      customerCountryID: 0,
      customerCPFCNPJ: '-',
      cardProcessingComission: this.transaction.cardProcessingComission / 100,
      estimatedExchangeCostBRL: this.transaction.estimatedExchangeCostBRL,
      cardProcessingCostBRL: this.transaction.cardProcessingCostBRL / 100,
      maxAntecipationCostBRL: this.transaction.maxAntecipationCostBRL,
      pdfUrl: this.paymentLink.pdfUrl || ''
    };

    this._logger.context('payment', this.dreampayPayment);

    this._paymentsService.apiPaymentsNewPost(this.dreampayPayment).subscribe(
      response => {
        this.paymentResponse = response;
        if (this.paymentResponse.returnCode === '00') {
          this.finishStepMessage = 'Pagamento efetuado com sucesso';
          this.onMessageChanged.emit(this.finishStepMessage);
        } else {
          this.finishStepMessage = 'Pagamento não autorizado.' + response.returnMessage;
          this.onMessageChanged.emit(this.finishStepMessage);
        }
        this._logger.context('paymentResponse', this.paymentResponse);
        this.sendVouchersAndRedirect(this.paymentResponse.returnCode === '00', !this.isCredit);
      },
      error => {
        this.finishStepMessage = 'Ocorreu um erro ao processar o pagamento.';
        this.onMessageChanged.emit(this.finishStepMessage);
        this.sendVouchersAndRedirect(false, !this.isCredit);
        this._logger.context('error', error);
      }
    );
  }

  sendVouchersAndRedirect(paid: boolean, debit: boolean) {
    if (paid) {
      if (this.paymentLink.linkType !== 2) {
        this._sharedFunctionsService.sendVouchers(
          this.paymentResponse?.paymentID,
          this.establishment,
          this.paymentLink
        );
      }
    }
    if (this.paymentLink.returnUrl && paid) {
      this.finishStepMessage = this.finishStepMessage + ' A redirecionar...';
      this.onMessageChanged.emit(this.finishStepMessage);
      window.setTimeout(redirect, 3000, this.paymentLink?.returnUrl);
    }
  }
}

function expDateValidators(c: FormControl) {
  const monthAndYear = /^(0[1-9]|1[0-2])\/?([2-9][0-9])$/;

  return monthAndYear.test(c.value)
    ? null
    : {
        validateInput: {
          valid: false
        }
      };
}

function redirect(returnUrl) {
  window.location.href = returnUrl;
}
