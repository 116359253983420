<form [formGroup]="finishForm">
  <div class="center">
    <div class="cards-bar my-4">
      <div class="field-container">
        <label for="mensagem" class="block text-gray-700 text-sm font-bold mb-2">Mensagem</label>
        <textarea id="mensagem" formControlName="mensagem" maxlength="500" type="text" rows="6" [attr.disabled]="true">
            Obrigado!
            </textarea
        >
      </div>
    </div>
    <div>
      <div class="field-container"></div>
    </div>
  </div>
</form>
