import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CardInformation } from '../models/bin.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class BinService {
  apiKey = '8d9f9f2faf5606fbfd79364057bc6625';
  apiURL = 'https://api.bincodes.com/bin/?format=json&api_key=' + this.apiKey + '&bin=';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient, private router: Router, private logger: LogService) {}

  getCardInformation(cardNumber: string): Observable<CardInformation> {
    return this.httpClient.get<CardInformation>(this.apiURL + cardNumber, this.httpOptions).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  getCardImagePath(cardScheme: string): string {
    let cardImgPath = '';
    switch (cardScheme.toLowerCase()) {
      case 'visa':
        cardImgPath = '../../../assets/img/visa.png';
        break;
      case 'mastercard':
        cardImgPath = '../../../assets/img/master.png';
        break;
      case 'amex':
        cardImgPath = '../../../assets/img/amex.png';
        break;
      case 'elo':
        cardImgPath = '../../../assets/img/elo.png';
        break;
      case 'aura':
        cardImgPath = '../../../assets/img/aura.png';
        break;
      case 'jcb':
        cardImgPath = '../../../assets/img/jcb.png';
        break;
      case 'diners':
        cardImgPath = '../../../assets/img/diners.png';
        break;
      case 'discover':
        cardImgPath = '../../../assets/img/discover.png';
        break;
      case 'hipercard':
        cardImgPath = '../../../assets/img/hipercard.png';
        break;
      case 'hiper':
        cardImgPath = '../../../assets/img/hiper.png';
        break;
      default:
        cardImgPath = '../../../assets/img/visa.png';
    }
    return cardImgPath;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.logger.error('BIN service errorMessage: ', errorMessage);
    return throwError(errorMessage);
  }
}
