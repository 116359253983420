export const environment = {
  appId: 'dreampay-payment',
  name: 'PAYMENT',
  env: 'dev',
  production: false,
  apiURL: 'https://dev-private-api.dreampay.com.br/api/',
  API_BASE_PATH: 'https://dev-private-api.dreampay.com.br',
  apiURLCielo: 'https://apisandbox.cieloecommerce.cielo.com.br',
  apiQueryURLCielo: 'https://apiquerysandbox.cieloecommerce.cielo.com.br',
  merchantIdCielo: '2deb432e-0d51-4dd5-a1dd-c9373b29bdfe',
  merchantKeyCielo: 'MMDNPUAHZKMCEISVDOSMMJKHTLXIYQTICFESLRDI'
};
