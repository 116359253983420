import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'finish-step',
  templateUrl: './finish-step.component.html',
  styleUrls: ['./finish-step.component.scss']
})
export class FinishStepComponent implements OnInit, OnChanges {
  @Input() message: string;

  finishForm: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this._initFinishForm();
  }

  private _initFinishForm() {
    this.finishForm = this._formBuilder.group({
      mensagem: [this.message || '']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.finishForm?.patchValue({
      mensagem: this.message || ''
    });
  }
}
