<mat-horizontal-stepper linear #stepper>
  <mat-step>
    <ng-template matStepLabel>Confirmar</ng-template>
    <confirm-step
      [paymentLink]="paymentLink"
      [establishment]="establishment"
      *ngIf="paymentLink && establishment"></confirm-step>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Pagar</ng-template>
    <payment-step
      [paymentLink]="paymentLink"
      [establishment]="establishment"
      (onMessageChanged)="updateMessage($event)"
      *ngIf="paymentLink && establishment"></payment-step>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Concluído</ng-template>
    <div>
      <iframe #frame name="frame" frameBorder="0"></iframe>
    </div>

    <finish-step [message]="finishStepMessage"></finish-step>
  </mat-step>
</mat-horizontal-stepper>
