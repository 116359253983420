<div class="cards-bar">
  <div class="w-full flex flex-row justify-between">
    <img src="../../../assets/img/visa.png" />
    <img src="../../../assets/img/master.png" />
    <img src="../../../assets/img/amex.png" />
    <img src="../../../assets/img/elo.png" />
    <img src="../../../assets/img/aura.png" />
  </div>
  <div class="w-full flex flex-row justify-between">
    <img src="../../../assets/img/jcb.png" />
    <img src="../../../assets/img/diners.png" />
    <img src="../../../assets/img/discover.png" />
    <img src="../../../assets/img/hipercard.png" />
    <img src="../../../assets/img/hiper.png" />
  </div>

  <div class="processado">Pagamento processado por {{ paymentGateway }}</div>
</div>
