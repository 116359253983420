import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CardIssuerFees,
  CountriesService,
  DreampayPayment,
  DreampayPaymentResponse,
  Establishments,
  EstablishmentsService,
  NotificationsService,
  PaymentLinks,
  PaymentLinksService,
  PaymentsService,
  Settings,
  SettingsService,
  SettingsUSDService
} from 'api-client';
import { getValidationConfigFromCardNo } from 'src/app/helpers/card.helper';
import { CardInformation } from 'src/app/models/bin.model';
import { CallbackMessage } from 'src/app/models/callback-message.model';
import { Translations } from 'src/app/models/translations.model';
import { LogService } from 'src/app/services/log.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { GenericValidator } from 'src/app/validators/cpfValidator';
import { luhnValidator } from 'src/app/validators/luhnValidator';
import { environment } from 'src/environments/environment';
import { BinService } from './../../services/bin.service';
import { NewNotification } from 'src/app/models/notification.class';
import { Transaction } from 'src/app/models/transaction.class';

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout.component.html',
  styleUrls: ['./payment-checkout.component.scss']
})
export class PaymentCheckoutComponent implements OnInit, OnDestroy {
  private serviceSubscription: Subscription = new Subscription();
  @ViewChild('frame', { static: true }) public frame: any;
  ccForm: FormGroup;
  orderForm: FormGroup;
  addressForm: FormGroup;
  finishForm: FormGroup;

  valueEur: number;

  paymentLink: PaymentLinks;
  establishment: Establishments;
  settings: Settings;
  bin: CardInformation;
  authenticationUrl = '';

  linkId: string;
  countries;

  bankName = 'DreamPay';
  cardImgPath = '';

  bloco1;
  bloco2;
  bloco3;
  bloco4;
  bloco5;
  blocks;

  isCredit = false;
  transaction = new Transaction();
  currentEnvironment: string = environment.env;
  timer: Subscription;

  cardIssuerFees: CardIssuerFees;

  establishmentCurrency;

  paymentProcessed = false;

  dreampayPayment: DreampayPayment;
  paymentResponse: DreampayPaymentResponse;

  openedNotification = new NewNotification();
  failedPayNotification = new NewNotification();

  private _translations: Translations = new Translations();

  constructor(
    private _paymentsService: PaymentsService,
    private _countriesService: CountriesService,
    private _paymentLinksService: PaymentLinksService,
    private _establishmentsService: EstablishmentsService,
    private _settingsService: SettingsService,
    private _settingsUSDService: SettingsUSDService,
    private _notificationsService: NotificationsService,
    private _sharedFunctionsService: SharedFunctionsService,
    private _binService: BinService,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _logger: LogService,
    private _router: Router
  ) {
    this._countriesService
      .apiCountriesGet()
      .pipe(take(1))
      .subscribe(countries => {
        this.countries = countries.filter(x => x.active || x.id === 24);
      });
  }

  ngOnInit() {
    this.orderForm = this._formBuilder.group({
      formHeaderHtml: new FormControl(''),
      quantity: new FormControl('1', [Validators.required]),
      customerName: new FormControl('', [Validators.required]),
      cpfCustomer: new FormControl(''),
      customerEmail: new FormControl('', [Validators.required]),
      customerPhoneNumber: new FormControl('', [Validators.required]),
      formFooterHtml: new FormControl(''),
      valueEUR: new FormControl(''),
      valueTotalEUR: new FormControl('')
    });

    this.addressForm = this._formBuilder.group({
      customerCity: new FormControl(''),
      customerAddress: new FormControl(''),
      customerZipCode: new FormControl(''),
      customerCountry: new FormControl('')
    });

    this.finishForm = this._formBuilder.group({
      mensagem: ['']
    });

    this.ccForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(27)]),
      cardNumber: new FormControl('', [Validators.required, Validators.minLength(11), luhnValidator()]),
      cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
      expirationDate: new FormControl('', [Validators.required, Validators.maxLength(7), expDateValidators]),
      securityCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(1),
        Validators.max(9999)
      ]),
      credit: new FormControl('1', [Validators.required]),
      parcelas: new FormControl(''),
      totalToPay: new FormControl(0)
    });

    this.onChanges();

    this.linkId = this._route.snapshot.paramMap.get('id');

    this._paymentLinksService
      .apiPaymentLinksIdGet(this.linkId)
      .pipe(take(1))
      .subscribe(
        result => {
          this.paymentLink = result;

          if (this.paymentLink.canceled) {
            this._router.navigate(['/canceled-link']);
          }

          this.valueEur = result.valueEUR;
          this.orderForm.controls.valueEUR.setValue(result.valueEUR);
          this.orderForm.controls.valueTotalEUR.setValue(result.valueEUR);
          this.orderForm.controls.formHeaderHtml.setValue(result.formHeaderHtml.split('\n').join('<br />'));
          this.orderForm.controls.formFooterHtml.setValue(result.formFooterHtml.split('\n').join('<br />'));
          this._establishmentsService
            .apiEstablishmentsIdGet(result.establishmentID)
            .pipe(take(1))
            .subscribe(
              establishment => {
                this.establishment = establishment;
                this._countriesService
                  .apiCountriesIdGet(this.establishment.countryID)
                  .pipe(take(1))
                  .subscribe(country => {
                    this.establishmentCurrency = country.currencySymbol;

                    this._sharedFunctionsService.initTranslations(country.uiLanguage.trim()).subscribe(response => {
                      this._translations = response;
                    });

                    this.ccForm.controls.parcelas.setValue(1);

                    const ano = new Date().getFullYear();
                    const mes = new Date().getUTCMonth() + 1;
                    const dia = new Date().getUTCDate();
                    const intDate = ano * 10000 + mes * 100 + dia;

                    if (country.currencyPlural === 'euros') {
                      this._settingsService
                        .apiSettingsDateGet(intDate)
                        .pipe(take(1))
                        .subscribe(
                          settings => {
                            this.settings = settings;
                            this.transaction.exchangeRate =
                              Math.round(
                                this.settings.oficialExchangeRate *
                                  (1 + this.settings.iof + this.settings.spread) *
                                  10000 +
                                  0.5
                              ) / 10000;
                            this.calculateAmountToPay();
                            this._logger.context('exchangeRate', {
                              exchangeRate: this.transaction.exchangeRate
                            });
                          },
                          error => {
                            this._logger.toastError('Erro a obter as definições.', error);
                          }
                        );
                    } else {
                      this._settingsUSDService
                        .apiSettingsUSDDateGet(intDate)
                        .pipe(take(1))
                        .subscribe(
                          settings => {
                            this.settings = settings;
                            this.transaction.exchangeRate =
                              Math.round(
                                this.settings.oficialExchangeRate *
                                  (1 + this.settings.iof + this.settings.spread) *
                                  10000 +
                                  0.5
                              ) / 10000;
                            this.calculateAmountToPay();
                            this._logger.context('exchangeRate', {
                              exchangeRate: this.transaction.exchangeRate
                            });
                          },
                          error => {
                            this._logger.toastError('Erro a obter as definições.', error);
                          }
                        );
                    }
                  });
              },
              error => {
                this._logger.toastError('Erro a obter dados do Estabelecimento. ', error);
              }
            );
        },
        error => {
          this._logger.debug('Invalid link.');
          this._router.navigate(['/invalid-link']);
        }
      );
  }

  async sendAlertOpened() {
    if (this.orderForm.controls.name.value.toString() !== '') {
      const uiLanguage = await this._sharedFunctionsService.getUiLanguageCode(this.establishment.countryID);

      this.openedNotification.emailAddress = this.establishment.emailAddress;
      this.openedNotification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
      this.openedNotification.subject = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      this.openedNotification.body =
        uiLanguage === 'en'
          ? 'Hello, ' +
              this.establishment.establismentName +
              '<br/>' +
              ' The client ' +
              this.orderForm.controls.customerName.value.toString() ||
            '' + ' with the cpf: ' + this.orderForm.controls.cpfCustomer.value.toString() ||
            '' + ', email: ' + this.orderForm.controls.customerEmail.value.toString() ||
            '' + ' and phone number: ' + this.orderForm.controls.customerPhoneNumber.value.toString() ||
            '' +
              +' has opened your payment link: ' +
              "<br/>Link: <a href='https://" +
              this.paymentLink.link +
              "' target='_blank'>https://" +
              this.paymentLink.link +
              '</a>' +
              "<br/><img src='" +
              this.paymentLink.qrCodeUrl +
              "' alt='qr-code' style='width: 250px; height:250px'>" +
              ' with the value of ' +
              this.orderForm.controls.valueEUR.value.toString() +
              this.establishmentCurrency
          : 'Olá, ' +
              this.establishment.establismentName +
              '<br/>' +
              'O cliente ' +
              this.orderForm.controls.customerName.value.toString() ||
            '' + ' com o cpf: ' + this.orderForm.controls.cpfCustomer.value.toString() ||
            '' + ', email: ' + this.orderForm.controls.customerEmail.value.toString() ||
            '' + ' e número de telemóvel: ' + this.orderForm.controls.customerPhoneNumber.value.toString() ||
            '' +
              ' abriu o seu link de checkout de pagamento: ' +
              "<br/>Link: <a href='https://" +
              this.paymentLink.link +
              "' target='_blank'>https://" +
              this.paymentLink.link +
              '</a>' +
              "<br/><img src='" +
              this.paymentLink.qrCodeUrl +
              "' alt='qr-code' style='width: 250px; height:250px'>" +
              ' com o valor de ' +
              this.orderForm.controls.valueEUR.value +
              this.establishmentCurrency;
      this.openedNotification.emailTitle = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      this.openedNotification.imageToUse = 'order-place.png';

      this.openedNotification.language = uiLanguage.toLowerCase();

      this._notificationsService
        .apiNotificationsPost(this.openedNotification)
        .pipe(take(1))
        .subscribe(
          result => {
            this._logger.debug(result);
          },
          error => {
            this._logger.error(error);
          }
        );
    }
  }

  onCardNumberBlur() {
    this.calculateAmountToPay();
  }

  onChanges() {
    this.orderForm.get('quantity').valueChanges.subscribe(x => {
      this.orderForm.controls.valueTotalEUR.setValue(x * this.valueEur);
    });

    this.serviceSubscription.add(
      this.ccForm.controls.cardNumber.valueChanges.subscribe(
        val => {
          this.blocks = this.ccForm.controls.cardNumber.value.split(' ');
          if (Array.isArray(this.blocks)) {
            this.bloco1 = this.blocks[0];
            this.bloco2 = this.blocks[1];
            this.bloco3 = this.blocks[2];
            this.bloco4 = this.blocks[3];
            this.bloco5 = this.blocks[4];
          } else {
            this.bloco1 = '';
            this.bloco2 = '';
            this.bloco3 = '';
            this.bloco4 = '';
            this.bloco5 = '';
          }
          const cardNumber = this.ccForm.controls.cardNumber.value.replaceAll(' ', '');

          if (cardNumber.length == 8) {
            this._binService
              .getCardInformation(cardNumber.substring(0, 6))
              .pipe(take(1))
              .subscribe(
                result => {
                  this.bin = result;
                  this._logger.context('CardInformation', this.bin);
                  this.bankName = result.bank;
                  this.cardImgPath = this._binService.getCardImagePath(this.bin?.card || 'visa');
                  this.calculateAmountToPay();
                },
                error => {
                  this._logger.toastError('Erro a obter informações do cartão.', error);
                }
              );
          } else if (cardNumber.length < 8) {
            this.bankName = 'DreamPay';
          }
        },
        error => {
          this._logger.error('Erro nos dados do cartão.', error);
        }
      )
    );
  }

  submeter() {
    this.finishForm.controls.mensagem.setValue('Por favor aguarde enquanto processamos o pagamento...');
    this._logger.context('form', this.ccForm.value);

    if (!this.bin) {
      this._binService
        .getCardInformation(this.ccForm.controls.cardNumber.value.replaceAll(' ', '').substring(0, 6))
        .pipe(take(1))
        .subscribe(
          result => {
            this.bin = result;
            this._logger.context('CardInformation', this.bin);
          },
          error => {
            this._logger.toastError('Erro a obter informações do cartão.', error);
          }
        );
    }

    this._logger.context('isCredit', {
      isCredit: this.isCredit,
      valueEUR: this.orderForm.controls.valueTotalEUR.value
      // valueEUR: this.paymentLink.valueEUR
    });

    this._logger.sendToSentry('Payment button clicked.');

    this.dreampayPayment = {
      posID: 'payment-link',
      establishmentID: this.establishment.id,
      softDescriptor: this.establishment.softDescriptor,
      establismentName: this.establishment.establismentName,
      cardHolder: this.ccForm.controls.name.value,
      cardNumber: this.ccForm.controls.cardNumber.value.replaceAll(' ', ''),
      cardExpirationDate:
        this.ccForm.controls.expirationDate.value.slice(0, 2) +
        '/20' +
        this.ccForm.controls.expirationDate.value.slice(2, 4),
      securityCode: this.ccForm.controls.securityCode.value,
      cardType: this.isCredit ? 'credit' : 'debit',
      orderNumber: (this.establishment.id.toString() + this._sharedFunctionsService.generateRandomLetter())
        // +this.paymentLink.orderNumber
        .substring(0, 16),
      customerName: this.orderForm.controls.customerName.value,
      purchaseValueEUR: this.paymentLink.valueEUR,
      totalValueEUR: this.transaction.totalValueEUR,
      totalValueBRL: 100 * this.transaction.installmentValueBRL * this.ccForm.controls.parcelas.value,
      installmentValueBRL: this.transaction.installmentValueBRL,
      paymentMethod: this.transaction.paymentMethod,
      installments: this.ccForm.controls.parcelas.value,
      readMethod: this.transaction.readMethod,
      cpFofCardOwner: this.ccForm.controls.cpf.value,
      cpfName: '-',
      exchangeRate: this.transaction.exchangeRate,
      paymentLink: this.paymentLink.id,
      customerEmail: this.orderForm.value.customerEmail,
      currencyCode: this.establishment.currencyCode,
      customerPhoneNumber: this.orderForm.value.customerPhoneNumber,
      customerAddress: this.addressForm.value.customerAddress,
      customerZipCode: this.addressForm.value.customerZipCode,
      customerCity: this.addressForm.value.customerCity,
      customerCountryID: this.addressForm.value.customerCountry,
      customerCPFCNPJ: this.orderForm.value.cpfCustomer,
      cardProcessingComission: this.transaction.cardProcessingComission / 100,
      estimatedExchangeCostBRL: this.transaction.estimatedExchangeCostBRL,
      cardProcessingCostBRL: this.transaction.cardProcessingCostBRL / 100,
      maxAntecipationCostBRL: this.transaction.maxAntecipationCostBRL,
      pdfUrl: ''
    };

    this._logger.context('payment', this.dreampayPayment);

    console.debug(JSON.stringify(this.dreampayPayment));

    this._paymentsService.apiPaymentsNewPost(this.dreampayPayment).subscribe(
      response => {
        this.paymentResponse = response;
        if (this.paymentResponse.returnCode === '00') {
          this.finishForm.controls.mensagem.setValue('Pagamento efetuado com sucesso');
        } else {
          this.finishForm.controls.mensagem.setValue('Pagamento não autorizado.' + response.returnMessage);
        }
        this._logger.context('paymentResponse', this.paymentResponse);
        this.saveTransactionRede(this.paymentResponse.returnCode === '00', !this.isCredit);
      },
      error => {
        this.finishForm.controls.mensagem.setValue('Ocorreu um erro ao processar o pagamento.');
        this.saveTransactionRede(false, !this.isCredit);
        this._logger.context('error', error);
      }
    );
  }

  saveTransactionRede(paid: boolean, debit: boolean) {
    if (paid) {
      this._sharedFunctionsService.sendVouchers(this.paymentResponse.paymentID, this.establishment, this.paymentLink);
    } else {
      this.sendFailedPayEmail(this.transaction);
    }
    if (this.paymentLink.callbackUrl) {
      let callbackMessage: CallbackMessage = {
        apiKey: this.establishment.apiKey,
        paymentLink: this.paymentLink.id,
        status: this.transaction.paid ? 200 : 400,
        message: this.finishForm.controls.mensagem.value.toString()
      };
      this._sharedFunctionsService.postToCallbackUrl(this.paymentLink.callbackUrl, callbackMessage);
      this._sharedFunctionsService.postToCallbackUrl(
        'https://webhook.site/3587d938-bef5-479d-be94-c4b8e8769824',
        callbackMessage
      );
    }
    if (this.paymentLink.returnUrl) {
      this.finishForm.controls.mensagem.setValue(
        this.finishForm.controls.mensagem.value.toString() + ' A redirecionar...'
      );
      window.setTimeout(redirect, 3000, this.paymentLink?.returnUrl);
    }
  }

  async sendFailedPayEmail(transaction) {
    const uiLanguage = await this._sharedFunctionsService.getUiLanguageCode(this.establishment.countryID);

    this.failedPayNotification.emailAddress = this.establishment.emailAddress;
    this.failedPayNotification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
    this.failedPayNotification.subject =
      uiLanguage === 'en' ? 'Failed payment From Checkout Link' : 'Falha no pagamento de Link de Checkout';
    this.failedPayNotification.body =
      uiLanguage === 'en'
        ? 'Hello, ' +
          transaction.establismentName +
          '<br/>' +
          'The client ' +
          this.orderForm.controls.customerName.value +
          ' with the cpf: ' +
          this.orderForm.controls.cpfCustomer.value +
          ', email: ' +
          this.orderForm.controls.customerEmail.value +
          ' and phone number: ' +
          this.orderForm.controls.customerPhoneNumber.value +
          ' has a failed payment attempt with the checkout link: ' +
          "<br/>Link: <a href='" +
          this.paymentLink.link +
          "' target='_blank'>" +
          this.paymentLink.link +
          '</a>' +
          ' with the value of ' +
          transaction.purchaseValueEUR +
          this.establishmentCurrency +
          '<br/>' +
          ' Here is some information of the failed transaction: ' +
          '<br/>' +
          'Installments: ' +
          transaction.installments +
          '<br/>' +
          'Installment Value: ' +
          transaction.installmentValueBRL +
          '$R' +
          '<br/>' +
          'Payment ID: ' +
          transaction.paymentID +
          '<br/>' +
          'Card brand: ' +
          transaction.cardType +
          '<br/>'
        : 'Olá, ' +
          transaction.establismentName +
          '<br/>' +
          'O cliente ' +
          this.orderForm.controls.customerName.value +
          ' com o cpf: ' +
          this.orderForm.controls.cpfCustomer.value +
          ', email: ' +
          this.orderForm.controls.customerEmail.value +
          ' e nº telemóvel: ' +
          this.orderForm.controls.customerPhoneNumber.value +
          ' tem uma tentativa de pagamento falhada com o link de checkout: ' +
          "<br/>Link: <a href='" +
          this.paymentLink.link +
          "' target='_blank'>" +
          this.paymentLink.link +
          '</a>' +
          ' com o valor de ' +
          transaction.purchaseValueEUR +
          this.establishmentCurrency +
          '<br/>' +
          ' Aqui estão algumas informações da transação falhada: ' +
          '<br/>' +
          'Parcelas: ' +
          transaction.installments +
          '<br/>' +
          'Valor parcela: ' +
          transaction.installmentValueBRL +
          '$R' +
          '<br/>' +
          'ID Pagamento: ' +
          transaction.paymentID +
          '<br/>' +
          'Tipo Cartão: ' +
          transaction.cardType +
          '<br/>';
    this.failedPayNotification.emailTitle =
      uiLanguage === 'en' ? 'Failed payment From Checkout Link' : 'Falha no pagamento de Link de checkout';

    this._logger.debug('this.notification', JSON.stringify(this.failedPayNotification));
    this.failedPayNotification.imageToUse = 'notification-warning.png';

    this.openedNotification.language = uiLanguage;

    this._notificationsService
      .apiNotificationsPost(this.failedPayNotification)
      .pipe(take(1))
      .subscribe(
        result => {
          this._logger.debug(result);
        },
        error => {
          this._logger.error(error);
        }
      );
  }

  getCardNumberControl(): AbstractControl | null {
    return this.ccForm?.get('cardNumber');
  }

  getExpirationDateControl(): AbstractControl | null {
    return this.ccForm?.get('expirationDate');
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onOptionsSelected(value: string) {
    this.calculateAmountToPay();
  }

  onRadioChange($event: MatRadioChange) {
    if ($event.value === '1') {
      this.isCredit = false;
      this.ccForm.controls.parcelas.setValue(1);
    } else {
      this.isCredit = true;
    }
    this.calculateAmountToPay();
  }

  calculateAmountToPay() {
    let totalValueBRL: number;
    let totalValueEUR: number;
    let installmentValueBRL: number;
    let efectiveExchangeRate: number =
      this.settings.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread);
    let exchangeCostBRL: number;
    let cardProcessingCostBRL: number;
    let maxAntecipationCostBRL: number;
    let cardProcessmentFee: number;

    if (this.isCredit) {
      switch (this.ccForm.controls.parcelas.value) {
        case 1:
          cardProcessmentFee = this.settings.cardProcessmentFee;
          break;
        case 2:
          cardProcessmentFee = this.settings.twoMonthsFee;
          break;
        case 3:
          cardProcessmentFee = this.settings.threeMonthsFee;
          break;
        case 4:
          cardProcessmentFee = this.settings.fourMonthsFee;
          break;
        case 5:
          cardProcessmentFee = this.settings.fiveMonthsFee;
          break;
        case 6:
          cardProcessmentFee = this.settings.sixMonthsFee;
          break;
        case 7:
          cardProcessmentFee = this.settings.sevenMonthsFee;
          break;
        case 8:
          cardProcessmentFee = this.settings.eightMonthsFee;
          break;
        case 9:
          cardProcessmentFee = this.settings.nineMonthsFee;
          break;
        case 10:
          cardProcessmentFee = this.settings.tenMonthsFee;
          break;
        case 11:
          cardProcessmentFee = this.settings.elevenMonthsFee;
          break;
        case 12:
          cardProcessmentFee = this.settings.twelveMonthsFee;
          break;

        default:
          cardProcessmentFee = this.settings.twelveMonthsFee;
          break;
      }
    } else {
      cardProcessmentFee = this.settings.debitCardProcessmentFee;
    }

    this.transaction.exchangeRate = efectiveExchangeRate;

    if (this.isCredit) {
      if (this.paymentLink.establishmentSupportsFinancingCosts) {
        totalValueEUR =
          this.orderForm.controls.valueEUR.value / (1 - this.settings.operacionalMargin - cardProcessmentFee);
      } else {
        totalValueEUR =
          this.orderForm.controls.valueEUR.value /
          (1 - this.settings.operacionalMargin - this.settings.antecipationFee - cardProcessmentFee) /
          (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
      }

      totalValueBRL = totalValueEUR * this.transaction.exchangeRate;
      installmentValueBRL = totalValueBRL / this.ccForm.controls.parcelas.value;
      this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));

      exchangeCostBRL = this.orderForm.controls.valueEUR.value * this.transaction.exchangeRate;
      cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
      maxAntecipationCostBRL =
        totalValueBRL /
        (1 - this.settings.antecipationFee) /
        (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
    } else {
      this.ccForm.controls.parcelas.setValue(1);
      totalValueEUR =
        this.orderForm.controls.valueTotalEUR.value / (1 - cardProcessmentFee - this.settings.operacionalMargin);
      totalValueBRL = totalValueEUR * this.transaction.exchangeRate;

      installmentValueBRL = totalValueBRL;

      this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));
      exchangeCostBRL = this.orderForm.controls.valueTotalEUR.value * this.transaction.exchangeRate;
      cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
      maxAntecipationCostBRL = 0;
    }

    this.transaction.cardProcessingComission = cardProcessmentFee * 100;
    this.transaction.estimatedExchangeCostBRL = Math.round(exchangeCostBRL * 100 + 0.5) / 100;
    this.transaction.cardProcessingCostBRL = Math.round(cardProcessingCostBRL * 100 + 0.5) / 100;
    this.transaction.maxAntecipationCostBRL = Math.round(maxAntecipationCostBRL * 100 + 0.5) / 100;
    this.transaction.totalValueBRL = Math.round(totalValueBRL * 100 + 0.5) / 100;

    this.transaction.totalValueEUR = Math.round(totalValueEUR * 100 + 0.5) / 100;
    this.transaction.installmentValueBRL = Math.round(installmentValueBRL * 100 + 0.5) / 100;
  }

  ngOnDestroy(): void {
    this.serviceSubscription.unsubscribe();
    this.timer.unsubscribe();
  }
}

function expDateValidators(c: FormControl) {
  const monthAndYear = /^(0[1-9]|1[0-2])\/?([2-9][0-9])$/;

  return monthAndYear.test(c.value)
    ? null
    : {
        validateInput: {
          valid: false
        }
      };
}

function wait(ms) {
  const start = new Date().getTime();
  let end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}

function redirect(returnUrl) {
  window.location.href = returnUrl;
}
