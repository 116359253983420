export class Translations {
  eCHello: string;
  eCConfirmationOrder: string;
  eCdoneInEstablishment: string;
  eCdeliveryProduct: string;
  eCvoucherAttachment: string;
  eCthankYouForChoosingDreampay: string;

  eOgreetings: string;
  eOimportantObs: string;
  eOemailAutomatic: string;
  eOinCaseNoVoucher: string;
  eOinCaseNoPurchase: string;

  eEforEstablishment: string;
  eEyourClientPurchased: string;
  eEgiveProductsToCustomer: string;
  eEvoucherAttached: string;
  eEexperienceSeeYouSoon: string;
  emailSubjectString: string;
}
