export class Transaction {
  id?: number;
  date?: string;
  posID: string;
  establishmentID?: number;
  establismentName: string;
  purchaseValueEUR?: number;
  totalValueEUR?: number;
  totalValueBRL?: number;
  installmentValueBRL?: number;
  paymentMethod?: number;
  installments?: number;
  readMethod?: number;
  cardHolder: string;
  cpFofCardOwner?: string;
  cpfName?: string;
  cardNumber: string;
  cardExpirationDate: string;
  cardType: string;
  paymentID: string;
  tid: string;
  authorizationCode: string;
  proofSale: string;
  paymentAccountReference: string;
  exchangeRate?: number;
  paid?: boolean;
  transferedToBranch?: boolean;
  transferedToBranchDate?: string;
  transferedToBranchValueEUR?: number;
  transferToBranchExchangeRate?: number;
  transferToBranchCostBRL?: number;
  paidToEstablishment?: boolean;
  paidToEstablishmentDate?: string;
  paidToEstablishmentValueEUR?: number;
  orderNumber: string;
  customerName: string;
  paymentLink?: string;
  cancelRequested?: boolean;
  cancelRequestDate?: string;
  canceled?: boolean;
  canceledDate?: string;
  canceledTID?: string;
  canceledAuthorizationCode?: string;
  canceledProofSale?: string;
  customerEmail?: string;
  currencyCode?: string;
  customerPhoneNumber?: string;
  exchangeCostBRL?: number;
  cardProcessingComission?: number;
  cardProcessingCostBRL?: number;
  antecipationCostBRL?: number;
  maxAntecipationCostBRL?: number;
  estimatedExchangeCostBRL?: number;
  customerAddress?: string;
  customerZipCode?: string;
  customerCity?: string;
  customerCountryID?: number;
  customerCPFCNPJ?: string;
  retainedValueOnEstablishmentCountryEUR?: number;
  operationalMarginBRL?: number;
  returnCode: string;
  returnMessage: string;
}
